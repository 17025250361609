// COLOR VARIABLES
$color-primary: #c69963;
$color-primary-dark: #b28451;

$color-secondary: #6439ff;
$color-grey: #888;

$border: rgb(230, 227, 227);

$bg-white: white;

// FONT VARIABLES
$font-primary: "Nunito", sans-serif;

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  // font-size: 62.5%; // 10px/16px = 62.5% -> 1rem = 10px
}

body {
  font-family: $font-primary;
  color: $color-grey;
  font-weight: 300;
  line-height: 1.6;
}


a {
  text-decoration: none;
}

ul {
  list-style: none;
}

.main {
  min-height: calc(100vh - 95px);
}

.small-elements .p-inputtext {
  padding: 5px !important;
  height: 30px;
  font-size: 11px;
}

.small-text {
  font-size: 10px;
}

.p-tabview .p-tabview-panels {
  padding:10px 0;
}